<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Notifications</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Detail</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="card" id="users">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-header">
            <h3 class="card-title">Notification Detail</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{name: 'AuthNotifications'}">
                <i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="custom-list">
              <ul>
                <li><strong>Title</strong> {{ value.title }}</li>
                <li><strong>Description</strong> {{ !_.isEmpty(value.description) ? value.description : 'NA' }}</li>
                <li><strong>Link</strong> {{ !_.isEmpty(value.link) ? value.link : 'NA' }}</li>
                <li><strong>Is Seen</strong>
                  <span class="badge bg-success" v-if="(value.is_seen)">Yes</span>
                  <span class="badge bg-danger" v-else>No</span>
                </li>
                <li><strong>Is Read</strong>
                  <span class="badge bg-success" v-if="(value.is_read)">Yes</span>
                  <span class="badge bg-danger" v-else>No</span>
                </li>
                <li><strong>Create At</strong> {{ !_.isEmpty(value.created_at) ? $moment(value.created_at).fromNow() : 'NA' }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {AuthAPI} from "@/services/api";

export default {
  name: 'UserView',
  metaInfo: {
    title: 'Notifications | Dotlines'
  },
  data: () => ({
    params:{},
    value: {},
    is_loading: false
  }),
  mounted: function () {
    let _this = this;
    _this.param = _this.$route.params;
    _this.getItem(_this.param.id);
  },
  methods: {
    getItem(id) {
      AuthAPI.showNotifications(id).then(item => {
        this.value = item;
        this.isLoading = false;
      });
    }
  }
}
</script>

